import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {JwtService} from '../../../../shared/services/jwtservice';
import {DialogController} from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import {config} from "../../../../shared/services/config";
import {AddKraPopupModal} from "../addkrapopup/addkrapopupmodal";
import {KraPerformanceReviewService} from "../../../../shared/services/kraperformancereviewservice";
import {ValidationControllerFactory, ValidationRules} from "aurelia-validation";
import {AlertpopupComponent} from "../../../alertpopup/alertpopupcomponent";

@inject(DialogController, JwtService, Router, ValidationControllerFactory, DialogService, KraPerformanceReviewService)
export class PerformanceReview {
    pageSize = config.kra_PazeSize;
    topicsPageSize = config.kra_PazeSize;
    ViewID = 2;
    isOpen = null;
    Successmessages = false;
    kraCategories = [];
    markascompleted = false;
    markascompletachivementpopup = false;
    activeTab = 'tab1';
    KraSuccessMessage = false;
    finalsubmitvalidation = false;
    openedIndex = null;
    kraTitleExit=false;
    EditkraSuccessMessage=false;
    publisheError=false;


    activateTab(tab) {
        this.activeTab = tab;
    }

    constructor(controller, JwtService, router, controllerFactory, DialogService, KraPerformanceReviewService) {
        this.controller = controller;
        this.jwtService = JwtService;
        this.empid = this.jwtService.getEmployeeId();
        this.dialogService = DialogService;
        this.KraPerformanceReview = KraPerformanceReviewService;
        this.controllerFactory = controllerFactory.createForCurrentScope();
        this.router = router;
    }

    activate(params) {
        // Access the value from the URL query parameter
        this.employeePerformanceReviewID = params.EmployeePerformanceReviewID;
       // console.log(this.employeePerformanceReviewID)
        this.EmployeeID=params.EmployeeID;
        this.ReviewTypeID=params.ReviewTypeID;
         this.assignedBulkData = params.bulkData;
    }

    attached() {
        this.KraPerformanceReview.GetEmployeePerformanceKRADetails(this.employeePerformanceReviewID,this.ReviewTypeID,this.EmployeeID)
            .then(data => {
                this.employeeAssignedKraDetails = data.CategoryList;
                this.employeeModel=data.employeeModel;
                this.krasamedata= data.CategoryList.EmployeePerformanceKRAList;
                this.weightages = data.CategoryList.map(item => item.TotalWeightage);
            })
    }

    EditKra(employeeperformance) {
        employeeperformance.isEditing = true;
        this.oldemployeeperformancedata = {...employeeperformance};
    }


    cancel(employeeperformance) {

        employeeperformance.isEditing = false;
        this.Weightageerror = "";
        this.isrequiredMessage = "";
    }

    deleteBulkAssignEmployee(bulkdata) {
        this.finalsubmitvalidation = false;
        const AssignEmployeeID = bulkdata.EmployeePerformanceReviewID;
        const EmployeePerformanceReviewIDs = this.assignedBulkData.map(item => item.EmployeePerformanceReviewID);


        // Check if AssignEmployeeID is present in EmployeePerformanceReviewIDs
        const isAssignEmployeeIDPresent = EmployeePerformanceReviewIDs.includes(AssignEmployeeID);

        // Get all EmployeePerformanceReviewIDs except AssignEmployeeID
        const EmployeePerformanceReviewIDsExceptAssign = EmployeePerformanceReviewIDs.filter(id => id !== AssignEmployeeID);
        const deletebulkids = {
            EmployeeID: this.empid,
            EmployeePerformanceReviewID: AssignEmployeeID

        };
        this.KraPerformanceReview.DeleteBulkAssignData(deletebulkids).then(data => {
            this.DeletebulkResponce = data;


            if (isAssignEmployeeIDPresent) {
                // If AssignEmployeeID is present, post the remaining EmployeePerformanceReviewIDs
                this.KraPerformanceReview.PostBulkAssignData(EmployeePerformanceReviewIDsExceptAssign).then(data => {
                    // this.bulkData = data;
                    // console.log(this.bulkData)
                    this.assignedBulkData = data;
                });
            }

        });
    }

    SaveEditedKra(CategoryID, KRATitle, Criteria, Weightage, EmployeePerformanceKRAID, EmployeePerformanceReviewID) {
        this.finalsubmitvalidation = false;
        this.controllerFactory.validate()
            .then(result => {
                if (result.valid) {
                    const data = {
                        EmployeeID: this.empid,
                        CategoryID: CategoryID,
                        KRATitle: KRATitle,
                        Criteria: Criteria,
                        Weightage: Weightage,
                        EmployeePerformanceKRAID: EmployeePerformanceKRAID,
                        EmployeePerformanceReviewID: EmployeePerformanceReviewID
                    };
                    if (data.KRATitle === "" || data.Criteria === "" || data.Weightage === "") {
                        this.KRATitleValidtion = "Field cannot be empty";
                        setTimeout(() => {
                            this.isrequiredMessage = null
                        }, 5000);
                        const errorElement = document.querySelector(".scrolltoerrorsuccess");
                        if (errorElement) {
                            errorElement.scrollIntoView({behavior: "auto", block: "start", inline: "nearest"});
                            window.scrollBy(0, -200);
                        }

                    }
                    else if (data.Weightage > 100) {
                        console.error("Weightage can't exceed more than 100")
                    }
                    else if (data.Criteria.length > 500)
                    {
                        console.error('erroe.length 500')
                    }
                    else {
                        if (this.oldemployeeperformancedata.KRATitle === data.KRATitle && this.oldemployeeperformancedata.Criteria === data.Criteria && this.oldemployeeperformancedata.Weightage === data.Weightage) {
                            this.isrequiredMessage = "";
                            this.Weightageerror = "";
                             this.EditkraSuccessMessage=true;
                            setTimeout(() => {
                                this.EditkraSuccessMessage = null
                            }, 5000);
                            const errorElement = document.querySelector(".scrolltoerrorsuccess");
                            if (errorElement) {
                                errorElement.scrollIntoView({behavior: "auto", block: "start", inline: "nearest"});
                                window.scrollBy(0, -200);
                            }
                            this.attached();


                        } else {
                            this.KraPerformanceReview.validationEmployeeCategoryKRAExist(data.EmployeePerformanceKRAID,data.EmployeePerformanceReviewID,data.CategoryID,data.KRATitle).then(exit=>{

                                if (exit === 1) {
                                    this.kraTitleExit=true;
                                    setTimeout(() => {
                                        this.kraTitleExit = null
                                    }, 5000);

                                    const errorElement = document.querySelector(".scrolltoerrorsuccess");
                                    if (errorElement) {
                                        errorElement.scrollIntoView({behavior: "auto", block: "start", inline: "nearest"});
                                        window.scrollBy(0, -200);
                                    }
                                }else {
                                    this.KraPerformanceReview.EditingPostCreateKRA(data).then(data => {
                                        this.attached();
                                        this.EditkraSuccessMessage=true;
                                        setTimeout(() => {
                                            this.EditkraSuccessMessage = null
                                        }, 5000);
                                        this.isrequiredMessage = "";
                                        this.Weightageerror = "";

                                    });
                                }
                            })
                        }
                    }}
            });
    }

    performanceClicks(event) {
        event.preventDefault();
        const parentDiv = event.target.closest('.parent-div');

        // Close all open categories except the clicked one
        const allOpenCategories = document.querySelectorAll('.parent-div.performance-open');
        allOpenCategories.forEach(openCategory => {
            if (openCategory !== parentDiv) {
                openCategory.classList.remove('performance-open');
            }
        });

        // Toggle the clicked category
        if (parentDiv) {
            parentDiv.classList.toggle('performance-open');
        }
    }

    performanceClicks(event, index) {
        event.preventDefault();
        if (this.openedIndex === index) {
            this.openedIndex = -1; // Close the currently open array
        } else {
            this.openedIndex = index; // Open the clicked array
        }
    }

    AddKraDialog() {
        this.finalsubmitvalidation = false;

        const employeePerformanceReviewID = this.employeePerformanceReviewID;

        if(employeePerformanceReviewID === undefined){
            this.EmployeePerformanceReviewIDs = this.assignedBulkData.map(item => item.EmployeePerformanceReviewID);
            const  data={ employeePerformanceReviewID :  this.EmployeePerformanceReviewIDs}
            this.dialogService.open({
                viewModel: AddKraPopupModal,
                model: data
            }).whenClosed((feedback) => {
                if (!feedback.wasCancelled) {
                    this.attached();
                    this.KraSuccessMessage = true;
                    setTimeout(() => {
                        this.KraSuccessMessage = null
                    }, 5000);
                }
            })
        }
        else{
            const  dataModel={
                EmployeePerformanceReviewID : this.employeePerformanceReviewID,
                ReviewTypeID:this.ReviewTypeID,
                EmployeeID:this.EmployeeID
            };
            console.log(dataModel)
            this.dialogService.open({
                viewModel: AddKraPopupModal,
                model: dataModel
            }).whenClosed((feedback) => {
                if (!feedback.wasCancelled) {
                    this.attached();
                    this.KraSuccessMessage = true;
                    setTimeout(() => {
                        this.KraSuccessMessage = null
                    }, 5000);
                }
            })
        }

    }

    /* markAsComplete(event, EmployeeGoalID, MarkAsCompletedIND, category) {
         const result = this.dialogService.open({
             viewModel: Markascompletemodel,
             model: '',
         }).whenClosed(response => {
             if (response.wasCancelled) {
                 this.markascompleted = false;
                 return;
             } else {
                 this.markascompleted = true;
                 this.markascompletachivementpopup = true;
                 const checkbox = document.querySelector(`input[name="achievement_${MarkAsCompletedIND == true}"]`);
                 if (checkbox) {
                     checkbox.checked = true;
                     const markascompletedata = {
                         EmployeeGoalID: EmployeeGoalID,
                         EmployeeID: this.empid
                     };
                     this.performaceService.MarkasCompletedKraCategories(markascompletedata)
                         .then(data => {
                             this.performaceService.KraCategories(this.empid, this.PeriodID, this.ReviewTypeID, this.ViewID)
                                 .then(data => {
                                     this.kraCategories = data.EmployeeCategoryList;
                                     const result = this.kraCategories?.find(item => item.EmployeeCategoryID === category.EmployeeCategoryID);
                                     const defaultCategory = result;
                                     this.toggleDisplay(defaultCategory);
                                 });
                         });
                 }
             }
         })
     }*/

    async DeleteKra(EmployeePerformanceKRAID) {
        this.finalsubmitvalidation = false;
        const result = await this.dialogService.open({
            viewModel: AlertpopupComponent,
            model: "",
        }).whenClosed(response => response);
        if (result.wasCancelled) {
            return;
        }
        const data = {
            EmployeeID: this.empid,
            EmployeePerformanceKRAID: EmployeePerformanceKRAID,
        };
        await this.KraPerformanceReview.DeleteEmployeePerformanceKRA(data)
            .then(data => {

        this.attached();
                                        this.DeletekraSuccessMessage=true;
                                        setTimeout(() => {
                                            this.DeletekraSuccessMessage = null
                                        }, 5000);
                                        this.isrequiredMessage = "";
                                        this.Weightageerror = "";
    });
}

    openFileDialog() {
        document.getElementById('attachments').click();
    }

    handleFileSelect(files) {
        const file = files[0];
        const maxSize = 2.5 * 1024 * 1024;
        if (file.size > maxSize) {
            this.fileMessage = 'File size should be less than 2.5 MB.';
            setTimeout(() => {
                this.fileMessage = null;
            }, 4000);
            this.removeFile();
            return;
        }
        else if (file.size <= maxSize) {
            this.files = file;
            let reader = new FileReader();
            reader.readAsDataURL(file);
            this.DocumentNames = file.name;
            reader.onload = () => {
                this.files = reader.result;
            };
        }
    }

    removeFile() {
        this.files = null;
        this.DocumentNames = '';
        const fileInput = document.getElementById('attachments');
        if (fileInput) {
            fileInput.value = '';
        }
    }

    toggleDisplay(category) {
        this.kraCategories.forEach(cat => {
            if (cat !== category) {
                cat.showDiv = false;
            }
        });
        category.showDiv = true;
    }
    openCategory(index) {
        this.isOpen = index;
    }

    BackPage() {
        this.router.navigateToRoute('KRAPerformanceReview/employeeperformancelist');
    }
    Publishing() {
        const finaldata = {
            EmployeePerformanceReviewID: this.employeePerformanceReviewID,
            EmployeeID: this.empid,
        };

        const total = this.weightages.reduce((acc, curr) => acc + curr, 0);
        if (total === 100){
            this.KraPerformanceReview.finalkrasubmit(finaldata).then(data => {

                });
                const successMessage = "KRA added successfully";
              //  const queryString = new URLSearchParams({ message: successMessage }).toString();
              //  const PerformacelistpageURL = `/#/KRAPerformanceReview/employeeperformancelist?${queryString}`;
               // window.location.href = PerformacelistpageURL;
            this.router.navigateToRoute('KRAPerformanceReview/employeeperformancelist',{successMessage});

        }else {
            const errorElement = document.querySelector(".scrolltoerrorsuccess");
                    if (errorElement) {
                        errorElement.scrollIntoView({behavior: "auto", block: "start", inline: "nearest"});
                        window.scrollBy(0, -200);
                    }
                    this.finalsubmitvalidation = true;
        }
            }

}
