import {inject } from 'aurelia-framework';
import { JwtService } from '../../../../shared/services/jwtservice';
import { DialogController } from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import { PerformanceMangemantService } from "../../../../shared/services/performancereviewservice";
import {config} from "../../../../shared/services/config";
import {AddAchivemetsPopup} from "../addachivemetsPopup/addachivemetsPopup";
import flatpickr from "flatpickr";
import {AlertpopupComponent} from "../../../alertpopup/alertpopupcomponent";
import {EditAddAchivemetsPopup} from "../editaddachivementspopup/editaddachivementspopup";
@inject(DialogController,JwtService,PerformanceMangemantService,DialogService)
export class ViewAchivemetsPopup{
    isLoading = false;
    static inject = [DialogController,Element];
    isDragging = false;
    dialog;
    pageSize = config.kra_PazeSize;

    constructor(controller,JwtService,PerformanceMangemantService,DialogService){
        this.controller = controller;
        this.jwtService= JwtService;
        this.dialogService = DialogService;
        this.empid = this.jwtService.getEmployeeId();
        this.performanceMangemant = PerformanceMangemantService;
    }
    activate(AchivemetsData){
        this.params =AchivemetsData ;
        this.ReviewTypeID=this.params.ReviewTypeID;
        this.ViewID=this.params.ViewID;
        this.ReferenceID=this.params.referenceID;
        this.Category=this.params.Category,
            this.KRAtitle=this.params.KRAtitle,
            this.MeasurementCriteria=this.params.MeasurementCriteria,
            this.TargetCompletionDateTxt=this.params.TargetCompletionDateTxt
        this.EmployeeCategoryID=this.params.EmployeeCategoryID,
            this.EmployeeGoalID=this.params.EmployeeGoalID,
            this.RecordStatusCode=this.params.RecordStatusCode,
            this.ReviewToText=this.params.ReviewToText,
            this.MarkasComplete=this.params.MarkasComplete,
            this.viewTypeId=this.params.viewTypeId
    }
    attached(){
        this.isLoading = true;
        this.performanceMangemant.GetAchievements(this.EmployeeGoalID,this.ReviewTypeID,this.ViewID).then(data=>
        {
            this.getAchievements=data;
            this.Files =  this.getAchievements.map(option => option.DocumentPath);
            if ( this.getAchievements.map(option => option.DocumentPath)) {
                this.file =  this.getAchievements.map(option => option.DocumentPath);
                this.DocumentName = this.getAchievements.map(option => option.DocumentPath);
            }
            this.isLoading = false;
        });
    }

    cancel(){
        this.controller.cancel();
    }
    downloadFile(DocumentPath){
        const downloadfile = config.img_url +DocumentPath;
        const link = document.createElement('a');
        link.href = downloadfile;
        link.download = this.DocumentPath;
        link.target = '_blank';
        link.click();
    }

}
