import {inject, bindingMode, bindable} from 'aurelia-framework';
import flatpickr from "flatpickr";
import {JwtService} from '../../../shared/services/jwtservice';
import {DialogController} from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import * as XLSX from 'xlsx';
import {config} from '../../../shared/services/config';
import {constants} from '../../../shared/services/constants';
import Scrollbar from 'smooth-scrollbar';
import {LoginService} from "../../../shared/services/loginservice";
import {Router} from 'aurelia-router';
import {LeavemanagementService} from "../../../shared/services/leavemanagementservice";
import {ExemptemployeeModel} from "../employeeperformancelist/ExemptemployeeModel/exemptemployeepopupmodal"
import {KraPerformanceReviewService} from "../../../shared/services/kraperformancereviewservice";

@inject(DialogController, JwtService, DialogService, KraPerformanceReviewService, Router, LoginService, LeavemanagementService)
export class Employeeperformancelist {

    pageSize = config.grid_PazeSize;
    searchTerm = '';
    search = '';
    FromDate = null;
    ToDate = null;
    isLoading = false;
    FavoritesExist = false;
    Successmessages = false;
    selectedDepartments = [];
    selectedPositions = [];
    selectedPosition = null;
    selectedEmployees = [];
    selectedEmployeePostion = [];
    selectAllChecked = false;
    params = null;
    showMessage=false;
    selectedStatus = 'PIN';
    ExemptSuccessMessage = false;
    RevertExemptSuccessMessage = false;

    sitemapId = constants.employeeperformancelistsitemapid;
    statusOptions = [
        {label: 'All', value: 'All', checked: false},
        {label: 'Published', value: 'INT', checked: false},
        {label: 'Pending Assignment', value: 'PIN', checked: true},
        {label: 'Exempted', value: 'CMP', checked: false},
    ];

    constructor(controller, JwtService, DialogService, KraPerformanceReviewService, router, loginservice, LeavemanagementService,) {
        this.controller = controller;
        this.jwtService = JwtService;
        this.dialogService = DialogService;
        this.empid = this.jwtService.getEmployeeId();
        this.router = router;
        this.loginservice = loginservice;
        this.leavemanagementService = LeavemanagementService;
        this.KraPerformanceReview = KraPerformanceReviewService;

    }

    activate(params) {
        if (params.successMessage === "KRA added successfully") {
            this.showMessage = true;
            // Automatically hide the message after 5 seconds
            setTimeout(() => {
                this.showMessage = false;
            }, 5000);
            // Rest of your logic
        } else {
            this.showMessage = false;
            // Rest of your logic
        }
        // Check the 'Pending Assignment' status checkbox by default
        this.statusOptions.find(option => option.label === 'Pending Assignment').checked = true;

        // Filter data based on the selected checkboxes
        this.filterData();
    }

    
    async attached() {
        this.leavemanagementService.GetPersonFavoriteItems(this.empid, this.sitemapId)
            .then(data => {
                this.FavoritesExist = data;
            });
        this.addToRecentItems();

        const windowWidth = window.innerWidth;
        if (windowWidth <= 1280) {
            Scrollbar.init(document.querySelector('.disable-desktop-scroll'));
        }
        this.KraPerformanceReview.GetEmployeePerformance(this.empid).then(data => {
            this.employeeperformancelist = data.EmployeePerformanceList;
            this.departments = data.DepartmentList;
            this.positionList = data.PositionList;
            this.reviewtypelist = data.ParameterTypeList;
           const mappedData = data.EmployeePerformanceList?.map(item => {
                this.mappedParameterTypeList = Object.entries(item.ParameterTypeList).map(([key, value]) => ({
                    id: key,
                    type: value
                }));

            });
         });
        const selectedStatusList = this.statusOptions
            .filter(status => status.checked)
            .map(status => status.value);
        this.filterData();

    }

    FetchExemptData = () => {
        this.KraPerformanceReview.GetEmployeePerformance(this.empid)
            .then(data => {
                this.employeeperformancelist = data.EmployeePerformanceList;

                this.sucess = "Exempt added successfully";
                this.exemptsucces = true;
                setTimeout(() => {
                    this.sucess = null;
                }, 4000)
            });
    };
    active() {

        const searchButton = document.getElementById('search-button');
        searchButton.addEventListener('click', handleSearch);
    }
    addToFavourites() {
        this.leavemanagementService.AddToFavourites(this.empid, this.sitemapId).then(data => {
            window.location.reload();
        });
    }
    addToRecentItems() {
        this.leavemanagementService.AddToRecentItems(this.empid, this.sitemapId).then(data => {

        });
    }
    FilterReset() {

        this.KraPerformanceReview.GetEmployeePerformance(this.empid).then(data => {
            this.employeeperformancelist = data.EmployeePerformanceList;
            this.departments = data.DepartmentList;
            this.positionList = data.PositionList;
            this.reviewtypelist = data.ParameterTypeList;
            const mappedData = data.EmployeePerformanceList?.map(item => {
                this.mappedParameterTypeList = Object.entries(item.ParameterTypeList).map(([key, value]) => ({
                    id: key,
                    type: value
                }));
            });
        });
        this.statusOptions.find(option => option.label === 'Pending Assignment').checked = true;
        // const selectedStatusList = this.statusOptions
        //     .filter(status => status.checked)
        //     .map(status => status.value);
        this.filterData();
    }
    ExemptEmployeeDialog(name, value,ReviewTypeID,EmployeeID) {

        if (name === "Exempt" || name ==="Revert Exempt") {
            const modelData = {
                name:name,
                EmployeePerformanceReviewID: value,
            };
            this.dialogService.open({viewModel: ExemptemployeeModel, model: modelData})
                .whenClosed((feedback) => {

                if (!feedback.wasCancelled) {
                    if (feedback.output == 'Revert Exempt'){
                        this.attached();
                        this.RevertExemptSuccessMessage = true;
                        setTimeout(() => {
                            this.RevertExemptSuccessMessage = null
                        }, 5000);

                    } else {
                    this.attached();
                    this.ExemptSuccessMessage = true;
                    setTimeout(() => {
                        this.ExemptSuccessMessage = null
                    }, 5000);
                    }
                }
            })

        }
        else if (name === "Assign") {
            const modelData = {
                EmployeePerformanceReviewID: value,
            };
            const queryString = new URLSearchParams(modelData).toString();
            const AssignperformancereviewURL = `/#/MyKRADetails/AssignKra?${queryString}`;
           // window.location.href = AssignperformancereviewURL;
            this.router.navigateToRoute('AssignKra', {EmployeePerformanceReviewID: value,ReviewTypeID:ReviewTypeID,EmployeeID:EmployeeID });
        }
    }
    ViewAssginedKraDetails(name,value){
        if (name === "View") {
            const modelData = {
                EmployeePerformanceReviewID: value,
            };
           // const queryString = new URLSearchParams(modelData).toString();
           // const ViewAssignperformancereviewURL = `/#/MyKRADetails/ViewAssignKra?${queryString}`;
           // window.location.href = ViewAssignperformancereviewURL;
            this.router.navigateToRoute('viewAssignKra',{EmployeePerformanceReviewID: value})
        }else{
            const modelData = {
                name:name,
                EmployeePerformanceReviewID: value,
            };
            this.dialogService.open({viewModel: ExemptemployeeModel, model: modelData})
        }
    }
    exportData() {
        const data = this.employeeperformancelist;

        const filename = 'employeeperformancelist';
        this.exportToExcel(data, filename);
    }
    exportToExcel(data, filename) {
        const headers = {
            EmployeeName:'Employee',
            DepartmentName:'Department',
            PositionLevelName:'Designation',
            ReviewPeriod:'Review period',
            ReviewType: 'Review type',
            Status: 'Status',
        };
        const filteredData = data.map(empPerformance => {
            return {

                EmployeeName:empPerformance.EmployeeName,
                DepartmentName:empPerformance.DepartmentName,
                PositionLevelName:empPerformance.PositionLevelName,
                ReviewPeriod:empPerformance.ReviewPeriod,
                ReviewType:empPerformance.ReviewType,
                Status: empPerformance.StatusText,

           };
        });
        const headerRow = Object.values(headers);
        const dataRows = filteredData.map(Performance => Object.values(Performance));
        const worksheet = XLSX.utils.aoa_to_sheet([headerRow, ...dataRows]);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        XLSX.writeFile(workbook, `${filename}.xlsx`);
    }
    clearSelectedDepartments() {
        this.selectedDepartments = [];
    }
    addActionClick(event) {
        // Find the dropdown relative to the clicked button
        const dropdown = event.target.closest('.relative').querySelector('.dropdown');
        // Toggle the visibility of the dropdown
        dropdown.classList.toggle('hidden');

        // Add event listener to close dropdown when clicking outside of it
        const handleOutsideClick = (event) => {
            if (!event.target.closest('.relative')) {
                dropdown.classList.add('hidden');
                document.removeEventListener('click', handleOutsideClick);
            }
        };

        // Add or remove event listener based on dropdown visibility
        if (!dropdown.classList.contains('hidden')) {
            document.addEventListener('click', handleOutsideClick);
        } else {
            document.removeEventListener('click', handleOutsideClick);
        }
    }


    filterClick() {
        event.preventDefault();
        const clickedDiv = event.target.parentNode;
        clickedDiv.classList.toggle("filter-open");
        // Remove 'filter-open' class from other divs
        const divs = document.querySelectorAll(".filter-open");
        divs.forEach((div) => {
            if (div !== clickedDiv) {
                div.classList.remove("filter-open");
            }
        });
        const handleOutsideClick = (event) => {
            if (!clickedDiv.contains(event.target)) {
                divs.forEach((div) => {
                    div.classList.remove("filter-open");
                });
                document.removeEventListener("click", handleOutsideClick);
            }
        };

        if (clickedDiv.classList.contains("filter-open")) {
            document.addEventListener("click", handleOutsideClick);
        } else {
            document.removeEventListener("click", handleOutsideClick);
        }
    }

    handleCheckboxChange(event, status) {
        // Update the checked status of the clicked option
        status.checked = event.target.checked;

        if (status.label !== 'All') {
            // Check if any checkbox other than 'All' is unchecked
            const anyUnchecked = this.statusOptions.slice(1).some(option => !option.checked);
            // If any checkbox is unchecked, uncheck the 'All' checkbox
            this.statusOptions[0].checked = !anyUnchecked;

        } else {
            // If 'All' is selected, update all other options accordingly
            this.statusOptions.forEach((option) => (option.checked = status.checked));
        }

        // Filter data based on the selected options
        this.filterData();
    }
    selectOption(option) {
        this.selectedOption = option;
        this.isDropdownOpen = false;
    }
    selectAllChanged(event) {
        const isChecked = this.statusOptions[0].checked;
        this.statusOptions.forEach((status, index) => {
            if (index !== 0) {
                status.checked = isChecked;
            }
        });
    }

    removeFilter(Allstatus,status) {
        if (status.label === 'All') {
            this.statusOptions.forEach(option => {
                option.checked = false;
            });
        } else {
            status.checked = false;
        }

    }
    get selectedStatuses() {

        return this.statusOptions
            .filter(status => status.checked);

    }

    handleCheckboxDepartmentChange(event, department) {
         const departmentID = department.DepartmentID;
        if (event.target.checked) {
            this.selectedDepartments.push(departmentID);
        } else {
            const index = this.selectedDepartments.indexOf(departmentID);
            if (index !== -1) {
                this.selectedDepartments.splice(index, 1);
            }
        }

        if (this.selectedPositions.length === 0) {
            this.selectedPositions = [""];
        }
        if (this.selectedOption === undefined) {
            this.selectedOption = "";
        }

        this.KraPerformanceReview.GetEmployeePerformanceFilterData(this.empid, this.selectedDepartments, this.selectedPositions, this.selectedOption)
            .then(data => {
                this.employeeperformancelist = data.EmployeePerformanceList;

            });
    }

    removeEmptyStrings(array) {
        return array.filter(item => typeof item === 'string' && item.trim() !== "");
    }

    handleCheckboxPositionChange(event, position) {
        const positionID = position.PositionID;
        if (event.target.checked) {
            this.selectedPositions.push(positionID);
        } else {
            const index = this.selectedPositions.indexOf(positionID);
            if (index !== -1) {
                this.selectedPositions.splice(index, 1);
            }
        }

        if (this.selectedDepartments.length === 0) {
            this.selectedDepartments = [""];
        }
        if (this.selectedOption === undefined) {
            this.selectedOption = "";
        }
        this.KraPerformanceReview.GetEmployeePerformanceFilterData(this.empid, this.selectedDepartments, this.selectedPositions, this.selectedOption)
            .then(data => {
                this.employeeperformancelist = data.EmployeePerformanceList;

            });
    }

    handleCheckboxChangeReviewType(event, item) {
        const itemId = item.id;

      //  Initialize this.selectedOption as an empty array if it's not already
        if (!Array.isArray(this.selectedOption)) {
            this.selectedOption = [];
        }

       const index = this.selectedOption.indexOf(itemId);

        if (event.target.checked) {
            if (index === -1) {
                this.selectedOption.push(itemId);
            }
        } else {
            if (index !== -1) {
                this.selectedOption.splice(index, 1);
            }
        }

        if (this.selectedDepartments.length === 0) {
            this.selectedDepartments = [""];
        }
        if (this.selectedPositions.length === 0) {
            this.selectedPositions = [""];
        }

         this.KraPerformanceReview.GetEmployeePerformanceFilterData(this.empid, this.selectedDepartments, this.selectedPositions, this.selectedOption)
            .then(data => {
                this.employeeperformancelist = data.EmployeePerformanceList;
            });
    }
    filterData() {
        this.KraPerformanceReview.GetEmployeePerformance(this.empid).then(data => {
            this.fullemployeeperformancelist = data.EmployeePerformanceList;

            if (!this.fullemployeeperformancelist) {
                this.employeeperformancelist = []; // Handle the case where data is not available
                return;
            }

            const selectedStatusLabels = this.statusOptions.slice(1) // Exclude 'All' checkbox
                .filter(option => option.checked)
                .map(option => option.label);
           if (selectedStatusLabels.length === 0) {
                // If no checkboxes are selected (excluding 'All'), show all data
                this.employeeperformancelist = this.fullemployeeperformancelist;
            } else {
                // Filter data based on selected checkboxes
                this.employeeperformancelist = this.fullemployeeperformancelist.filter(employee => {
                    // Check if any of the selected status labels exists in the employee's StatusText
                    return selectedStatusLabels.some(label => employee.StatusText.includes(label));
                });
            }
        });
    }

    selectEmployee(event, employeeData) {
        const employeeReviewID = employeeData.EmployeePerformanceReviewID;
        const employeePostionID = employeeData.PositionID;

        if (event.target.checked) {
            // Add the selected employeeData to your selectedEmployees or perform any other necessary operation
            this.selectedEmployees.push(employeeReviewID);
            this.selectedEmployeePostion.push(employeePostionID);

        } else {
            // Remove the deselected employeeData from your selectedEmployees or perform any other necessary operation
            const index = this.selectedEmployees.indexOf(employeeReviewID);
            const indexs = this.selectedEmployeePostion.indexOf(employeePostionID);
            if (index !== -1) {
                this.selectedEmployees.splice(index, 1);
            }
            if (indexs !== -1) {

                this.selectedEmployeePostion.splice(indexs, 1);
            }
        }
        // Check if there are already selected employees
        if (this.selectedEmployees.length > 0) {
            // Get the PositionLevelName of the first selected employee
            const firstSelectedEmployee = this.$EmployeeList.find(employee => this.selectedEmployees.includes(employee.EmployeePerformanceReviewID));
            const firstSelectedPositionLevelID = firstSelectedEmployee ? firstSelectedEmployee.PositionID : null;
            // Compare the PositionLevelName of the current employee with the first selected employee
            if (employeeData.PositionID !== firstSelectedPositionLevelID) {
                // If PositionLevelName is different, show validation message
                this.designationError = "Select only employees with the same designation";

            }else{
                this.designationError = "";

            }
        }

    }

    selectAll(event) {
        this.designationError = "";
        const isChecked = event.target.checked;

        // Update the isSelected property of all employees based on isChecked
        this.$EmployeeList.forEach(employee => {
            if (employee.StatusText !== 'Exempted' && employee.StatusText !== 'Published') {
                employee.isSelected = isChecked;
            }
        });

        // Extract EmployeePerformanceReviewID of selected employees
        this.selectedEmployees = [];
        this.$EmployeeList.forEach(employee => {
            if (employee.isSelected) {
                this.selectedEmployees.push(employee.EmployeePerformanceReviewID);
            }
        });
    }


    BulkSubmit() {
        const EmployeePerformanceReviewIDs = this.selectedEmployees;
        //const containsSameValues = this.selectedEmployeePostion.every((element, index, array) => element === array[0]);
        const containsSameValues = this.selectedEmployeePostion.length > 0 && this.selectedEmployeePostion.every((element, index, array) => element === array[0]);
        if (!containsSameValues) {
            // If validation fails, display a message or handle it accordingly
            this.designationError = "Select only employees with the same designation";
            // You can also trigger Aurelia validation explicitly if you want to display the validation message in your view
        }
        else{
            this.designationError = "";
            if(EmployeePerformanceReviewIDs.length < 2 ){
                this.bulkvalidation = "Please select atleast two employees"
            }
            else{
                this.bulkvalidation = "";
                this.designationError = "";

                if (EmployeePerformanceReviewIDs.length !== 0) {
                    this.KraPerformanceReview.PostBulkAssignData(EmployeePerformanceReviewIDs).then(data => {
                        this.bulkData = data;
                        if (this.bulkData) {
                            // this.router.navigateToRoute('#/MyKRADetails/AssignKra');
                            this.router.navigateToRoute('AssignKra', {bulkData: this.bulkData});
                        }
                    });
                }
            }
        }


    }
}