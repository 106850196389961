import {inject} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import 'flatpickr/dist/flatpickr.min.css';
import {JwtService} from '../../../../shared/services/jwtservice';
import {KraPerformanceReviewService} from "../../../../shared/services/kraperformancereviewservice";
import {ValidationControllerFactory, ValidationRules} from "aurelia-validation";
import {config} from "../../../../shared/services/config";

@inject(DialogController, KraPerformanceReviewService, JwtService, ValidationControllerFactory)
export class ExemptemployeeModel {
    isDragging = false;
    dialog;
    static inject = [Element];
    uploadedFiles = [];
    changedMode = 'true';
    pageSize = 5;
    ErrorMessageShow = false;
    isSubmitting = false;
    viewfile = true;


    constructor(DialogController, KraPerformanceReviewService, JwtService, controllerFactory) {
        this.controller = DialogController;
        this.KraPerformanceReviewService = KraPerformanceReviewService;
        this.jwtService = JwtService;
        this.EmpID = this.jwtService.getEmployeeId();
        this.controllerFactoryValue = controllerFactory.createForCurrentScope();

        ValidationRules
            .ensure('Reason').required().withMessage('Reason is required')
            .on(this);
    }

    activate(data) {
        this.Name = data.name;
       this.EmployeePerformanceReviewID = data.EmployeePerformanceReviewID;
    }

    attached() {
        this.KraPerformanceReviewService.GetExemptEmployeeData(this.EmployeePerformanceReviewID).then(data => {
            this.exemptemployeeData = data;
            this.employeName = data.EmployeeName;
            this.reviewType = data.ReviewType;
            this.reviewPeriod = data.ReviewPeriod;
            this.ViewDocumentPath = data.DocumentPath;
            this.ViewDocumentName = data.DocumentName;
        });

    }

    fileValidator() {
        const fileInput = document.querySelector('input[type="file"]');
        const allowedTypes = ['image/jpeg', 'image/png', 'application/msword', 'application/pdf'];
        fileInput.addEventListener('change', (event) => {
            const file = event.target.files[0];
            const fileType = file.type;
            if (!allowedTypes.includes(fileType)) {
                this.fileMessage = "Only JPEG, PNG, PDF and DOC files are allowed.";
                this.fileValidatorRule = true;
            } else {
                this.fileMessage = null;
                this.fileValidatorRule = false;
            }
        });
    }

    handleFileSelect(files) {
        this.viewfile = false;
        const file = files[0];
        const maxSize = 2.5 * 1024 * 1024; // Maximum file size in bytes (2.5 MB)

        if (file.size > maxSize) {
            this.fileMessage = 'File size should be less than 2.5 MB.';
            setTimeout(() => {
                this.fileMessage = null;
            }, 3000);
            this.removeFile();
            return;
        } else if (file.size < maxSize) {

            this.file = file;
            let reader = new FileReader();
            reader.readAsDataURL(file);
            this.DocumentName = file.name;
            reader.onload = () => {
                this.file = reader.result;
            };
        }
    }

    openFileDialog() {
        document.getElementById('attachments').click();
    }

    removeFile() {
        this.DocumentPath = "";
        this.DocumentName = "";
        const fileInput = document.getElementById('attachments');
        if (fileInput) {
            fileInput.value = '';
        }
    }

    downloadFile() {
        if (this.DocumentName.length > 0) {
            const downloadfile = config.img_url + this.DocumentPath;
            const link = document.createElement('a');
            link.href = downloadfile;
            link.download = this.DocumentName; // Replace 'filename' with the desired name of the downloaded file
            link.target = '_blank';
            link.click();
        }
    }

    Submit() {
        event.preventDefault();
        this.controllerFactoryValue.validate()
            .then(result => {
                if (result.valid) {
                    const refreshEvent = new CustomEvent('refreshExemptData');
                    event.preventDefault();
                    const exemptPostData = {
                        EmployeeID: this.EmpID,
                        EmployeePerformanceReviewID: this.EmployeePerformanceReviewID,
                        DocumentName: this.DocumentName,
                        DocumentPath: this.file,
                        Reason: this.Reason,
                        StatusCode: this.Name === "Exempt" ? null : "ACT"

                    };

                    if (this.Name == "Revert Exempt") {

                        this.KraPerformanceReviewService.PostRevertExemptEmploye(exemptPostData)
                            .then(data => {
                                this.controller.ok(this.Name);
                            })
                    } else {
                        this.KraPerformanceReviewService.PostExemptEmployeeData(exemptPostData)
                            .then(data => {
                                this.controller.ok(this.Name);
                                this.KraPerformanceReviewService.GetExemptEmployeeData(this.EmployeePerformanceReviewID).then(data => {
                                    this.exemptemployeeData = data;
                                    document.dispatchEvent(refreshEvent);
                                });
                            });
                    }

                }
            });

    }

    cancel() {
        this.controller.cancel();
    }

}
