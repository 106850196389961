import {inject, bindingEngine} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import 'flatpickr/dist/flatpickr.min.css';
import {JwtService} from '../../../../shared/services/jwtservice';
import {KraPerformanceReviewService} from "../../../../shared/services/kraperformancereviewservice";
import {ValidationControllerFactory, ValidationRules} from "aurelia-validation";

@inject(DialogController, JwtService, ValidationControllerFactory, KraPerformanceReviewService)
export class AddKraPopupModal {
    selectedOption = 'createNew';
    selectedKRARepository = null;
    isDragging = false;
    dialog;
    static inject = [Element];
    uploadedFiles = [];
    changedMode = 'true';
    pageSize = 5;
    ErrorMessageShow = false;
    checkBoxError = false;
    isSubmitting = false;
    EmployeePerformanceKRAID = 0;
    kraTitleExit = false;


    constructor(DialogController, JwtService, controllerFactory, KraPerformanceReviewService, bindingEngine) {
        this.controller = DialogController;
        this.KraPerformanceReviewService = KraPerformanceReviewService;
        this.jwtService = JwtService;
        this.EmpID = this.jwtService.getEmployeeId();
        this.bindingEngine = bindingEngine;
        this.controllerFactory = controllerFactory.createForCurrentScope();

        ValidationRules
            .ensure('kratitle').required().withMessage('KRA title is required')
            .ensure('selectedCategory').required().withMessage('Category is required')
            .ensure('Weightage').required().withMessage('Weightage is required')
            .ensure('Criteria').required()
            .on(this);
    }

    activate(params) {
          //this.EmployeePerformanceReviewID = params;
            this.EmployeePerformanceReviewID=params.EmployeePerformanceReviewID;
            this.ReviewTypeID=params.ReviewTypeID;
            this.EmployeeID=params.EmployeeID;
            this.bulkemployeePerformanceReviewID = params.employeePerformanceReviewID;



    }

    attached() {
        this.KraPerformanceReviewService.KraCategoryDropdown(this.ReviewTypeID,this.EmployeeID).then(data => {
            this.categoryDropdown = data;
        });
        this.KraPerformanceReviewService.KRARepository(this.ReviewTypeID,this.EmployeeID).then(data => {
            this.EmployeePerformanceReviewID
            this.KRARepository = data;
           // console.log(data);
        });
    }


    fileValidator() {
        const fileInput = document.querySelector('input[type="file"]');
        const allowedTypes = ['image/jpeg', 'image/png', 'application/msword', 'application/pdf'];
        fileInput.addEventListener('change', (event) => {
            const file = event.target.files[0];
            const fileType = file.type;
            if (!allowedTypes.includes(fileType)) {
                this.fileMessage = "Only JPEG, PNG, PDF and DOC files are allowed.";
                this.fileValidatorRule = true;
            } else {
                this.fileMessage = null;
                this.fileValidatorRule = false;
            }
        });
    }

    handleFileSelect(files) {
        const file = files[0];
        this.file = file;
        const allowedFormats = ['.jpg', '.png', '.doc', '.pdf'];
        const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
        if (allowedFormats.includes(fileExtension)) {
            const reader = new FileReader();
            reader.onload = (event) => {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                this.documentFileName = file.name;
                reader.onload = () => {
                    this.file = reader.result;
                };
            };
            reader.readAsDataURL(file);
        } else {
            // Handle invalid file format here (e.g., display an error message)
            this.uploadErrors = [{error: {message: ''}}];

        }
    }


    openFileDialog() {
        document.getElementById('attachments').click();
    }

    removeFile() {
        this.file = null;
        this.DocumentName = '';
        const fileInput = document.getElementById('attachments');
        if (fileInput) {
            fileInput.value = '';
        }
    }

    checkboxChanged(KRARepositorydata) {

        // Filter out the unchecked checkboxes
        const checkedItems = this.KRARepository.filter(item => item.isSelected);
        // Extract the IDs of the checked items

        const checkedIDs = checkedItems.map(item => item.KraCategoryQuestionID);
        // Log or send the checked IDs to the API
        return checkedIDs;
        // Call your API with checkedIDs here
    }

    KRARepositorySubmit(KRAPRRepositoryID) {
        const refreshEvent = new CustomEvent('refreshExemptData');
        const checkedIDs = this.checkboxChanged();
        event.preventDefault();
        this.KRARepositoryIds = checkedIDs;
        this.EmployeePerformanceReviewID = this.EmployeePerformanceReviewID;

        if (checkedIDs.length === 0) {
            this.checkBoxError = true;
            setTimeout(() => {
                this.checkBoxError = null
            }, 10000);
            // Show an error message or perform any necessary action
            console.error('No checkboxes are checked. Please select at least one checkbox.');

        }
        else {

            this.KraPerformanceReviewService.KRARepositoryPost(this.KRARepositoryIds, this.EmployeePerformanceReviewID)
                .then(data => {
                    // this.controller.cancel();
                    this.controller.ok(true);
                });
        }
    }

    cancel() {
        this.controller.cancel();
    }
    CreateNewKRASubmit() {
        event.preventDefault();
        this.controllerFactory.validate()
            .then(result => {
                if (result.valid) {

                    if(this.bulkemployeePerformanceReviewID && this.bulkemployeePerformanceReviewID.length > 1){
                        const data = {
                            EmployeeID: this.EmpID,
                            EmployeePerformanceKRAIDs: this.bulkemployeePerformanceReviewID,
                            KRATitle: this.kratitle,
                            CategoryID: this.selectedCategory,
                            Criteria: this.Criteria,
                            Weightage: this.Weightage,

                        };
                        console.log(data)
                        if (data.Weightage > 100) {
                            // Display an error message or perform any necessary action
                            console.error("Weightage can't exceed more than 100.");

                        }
                        else if (data.Criteria.length > 500) {
                            console.error('erroe.length 500')
                        }
                        else {
                            console.log(data);
                            this.KraPerformanceReviewService.PostCreateKRA(data)
                                .then(data => {
                                    // this.controller.cancel();
                                    this.controller.ok(true);
                                });
                        }
                        // else {
                        //     this.KraPerformanceReviewService.validationEmployeeCategoryKRAExist(this.EmployeePerformanceKRAID, this.bulkemployeePerformanceReviewID, this.selectedCategory, this.kratitle).then(exit => {
                        //         if (exit === 1) {
                        //             this.kraTitleExit = true;
                        //         } else {
                        //             this.KraPerformanceReviewService.PostCreateKRA(data)
                        //                 .then(data => {
                        //                     // this.controller.cancel();
                        //                     this.controller.ok(true);
                        //                 });
                        //         }
                        //     });
                        // }
                    }
                    else{
                        const data = {
                            EmployeeID: this.EmpID,
                            EmployeePerformanceReviewID: this.EmployeePerformanceReviewID,
                            KRATitle: this.kratitle,
                            CategoryID: this.selectedCategory,
                            Criteria: this.Criteria,
                            Weightage: this.Weightage,

                        };
                        if (data.Weightage > 100) {
                            // Display an error message or perform any necessary action
                            console.error("Weightage can't exceed more than 100.");
                        }
                        else if (data.Criteria.length > 500) {
                            console.error('erroe.length 500')
                        }
                        else {
                            this.KraPerformanceReviewService.validationEmployeeCategoryKRAExist(this.EmployeePerformanceKRAID, this.EmployeePerformanceReviewID, this.selectedCategory, this.kratitle).then(exit => {
                                if (exit === 1) {
                                    this.kraTitleExit = true;
                                } else {
                                        this.KraPerformanceReviewService.PostCreateKRA(data)
                                        .then(data => {
                                            // this.controller.cancel();
                                            this.controller.ok(true);
                                        });
                                }
                            });
                        }

                    }

                }
            });
    }
}

