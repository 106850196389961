import {inject} from 'aurelia-framework';
import {JwtService} from '../../../../shared/services/jwtservice';
import {DialogController} from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import {PerformanceMangemantService} from "../../../../shared/services/performancereviewservice";
import {config} from "../../../../shared/services/config";
import {ViewAchivemetsPopup} from "./../viewachivemetsPopup/viewachivemetsPopup";


@inject(DialogController, JwtService, DialogService, PerformanceMangemantService)
export class ViewPerformanceReview {
    pageSize = config.kra_PazeSize;
    topicsPageSize = config.kra_PazeSize;
    viewTypeId = false;
    ViewID = 2;
    isOpen = null;
    Successmessages = false;
    kraCategories = [];
    markascomplete = false;
    activeTabIndex = 0;
    selectedDiv = 0;

    constructor(controller, JwtService, DialogService, PerformanceMangemantService) {
        this.controller = controller;
        this.jwtService = JwtService;
        this.empid = this.jwtService.getEmployeeId();
        this.dialogService = DialogService;
        this.performaceService = PerformanceMangemantService;
    }

    activate(approveData) {
        this.params = approveData;
        this.ActionName = this.params.ActionName,
            this.RecordStatusCode = this.params.RecordStatusCode,
            this.PeriodID = this.params.PeriodID,
            this.KRAPerformanceReviewID = this.params.KRAPerformanceReviewID,
            this.ReviewTypeID = this.params.ReviewTypeID,
            this.ReviewFromText = this.params.ReviewFromText,
            this.ReviewToText = this.params.ReviewToText
    }

    attached() {
        this.performaceService.KraCategories(this.empid, this.PeriodID, this.ReviewTypeID, this.ViewID)
            .then(data => {
                this.kraCategories = data.EmployeeCategoryList;
                if (this.kraCategories.length > 0) {
                    const defaultCategory = this.kraCategories[0]; // Change this to the desired category
                    this.toggleDisplay(defaultCategory);
                }
            });
        this.performaceService.GetReviewHistory(this.empid, this.PeriodID, this.ReviewTypeID)
            .then(data => {
                this.GetReviewHistory = data;
            });

        this.performaceService.GetAdditionalNotesandComments(this.KRAPerformanceReviewID)
            .then(data => {
                this.additionalcomments = data;
                this.Files = this.additionalcomments.map(option => option.DocumentPath);
                if (this.additionalcomments.map(option => option.DocumentPath)) {
                    this.file = this.additionalcomments.map(option => option.DocumentPath);
                    this.DocumentName = this.additionalcomments.map(option => option.DocumentPath);
                }
            });
    }

    activateTab(index) {
        this.selectedDiv = index;
        this.kraCategories.forEach((category, idx) => {
            category.showDiv = (index === idx);
        });
    }


    openFileDialog() {
        document.getElementById('attachments').click();
    }

    handleFileSelect(files) {
        const file = files[0];
        const maxSize = 2.5 * 1024 * 1024;
        if (file.size > maxSize) {
            this.fileMessage = 'File size should be less than 2.5 MB.';
            setTimeout(() => {
                this.fileMessage = null;
            }, 4000);
            this.removeFile();
            return;
        }
        else if (file.size <= maxSize) {
            this.files = file;
            let reader = new FileReader();
            reader.readAsDataURL(file);
            this.DocumentNames = file.name;
            reader.onload = () => {
                this.files = reader.result;
            };
        }
    }

    removeFile() {
        this.files = null;
        this.DocumentNames = '';
        const fileInput = document.getElementById('attachments');
        if (fileInput) {
            fileInput.value = '';
        }
    }

    toggleDisplay(category) {
        this.kraCategories.forEach(cat => {
            if (cat !== category) {
                cat.showDiv = false;
            }
        });

        category.showDiv = !category.showDiv;
    }

    AchivemetsPopup(referenceID, Category, Goal, MeasurementCriteria, TargetCompletionDateTxt, EmployeeCategoryID, EmployeeGoalID) {
        const AchivemetsData = {
            ReviewTypeID: this.ReviewTypeID,
            ViewID: this.ViewID,
            referenceID: referenceID,
            Category: Category,
            KRAtitle: Goal,
            MeasurementCriteria: MeasurementCriteria,
            TargetCompletionDateTxt: TargetCompletionDateTxt,
            EmployeeCategoryID: EmployeeCategoryID,
            EmployeeGoalID: EmployeeGoalID,
            RecordStatusCode: this.RecordStatusCode,
            ReviewToText: this.ReviewToText,
            MarkasComplete: this.markascomplete,
            viewTypeId: this.viewTypeId,
        };
        this.dialogService.open({viewModel: ViewAchivemetsPopup, model: AchivemetsData})
            .whenClosed((feedback) => {
                if (!feedback.wasCancelled) {
                    this.messages = "KRA updated successfully.";
                    this.Successmessages = true;
                    setTimeout(() => {
                        this.messages = null
                    }, 5000);
                }
            })
    }

    openCategory(index) {
        this.isOpen = index;
    }

    kraClick() {
        event.preventDefault();
        const clickedDivopen = event.target.closest('div.goalSection > div');
        if (clickedDivopen) {
            clickedDivopen.classList.toggle('content-open');
        }
    }

    BackPage() {
        const performancereviewURLBackPage = `/#/KRAPerformanceReview/MyGoalSheet`;
        window.location.href = performancereviewURLBackPage;
    }
}
